@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Martian+Mono:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  text-decoration: none !important;
}

:root {
  --protean-gray: #a4a8b1;
  --protean-dark-gray: #24272a;
  --protean-black: #616674;
  --protean-white: #f7f9fa;
}

[data-theme="Light"] {
  --checkbox_color: #6245D8;
  --link_color: rgb(26 113 239);
  --sec_btn_color: #636363;
  --selectedtab_btn_icon_color: invert(30%) sepia(69%) saturate(4342%) hue-rotate(243deg) brightness(88%) contrast(92%);
  --navbar_icons_color: invert(72%) sepia(9%) saturate(337%) hue-rotate(198deg) brightness(87%) contrast(88%);
  --navbar_icons_hover_color: invert(31%) sepia(100%) saturate(1059%) hue-rotate(230deg) brightness(84%) contrast(105%);
  --layout_text_color: #6B6D83;
  --user_menu_bg: #FDF0AC;
  --sidemenu_icons_color: invert(44%) sepia(7%) saturate(1029%) hue-rotate(197deg) brightness(95%) contrast(91%);
  --sidemenu_icon_hover_color: invert(26%) sepia(88%) saturate(2129%) hue-rotate(241deg) brightness(86%) contrast(96%);
  --sidemenu_ul_icon_hover_color: invert(60%) sepia(53%) saturate(2277%) hue-rotate(234deg) brightness(134%) contrast(98%);
  /* --sidemenu_ul_icon_hover_grey_color: invert(93%) sepia(1%) saturate(4589%) hue-rotate(194deg) brightness(106%) contrast(76%); */
  --sidemenu-parent-selected-item-bg: #EFECFB;
  --main-component-bg: rgba(255, 255, 255, 0.60);
  --overlay-component-bg: rgba(255, 255, 255);
  --layout-component-bg: #F4F5F7;
  --content_bg: radial-gradient(circle 300px at 151px, #e3e1ff, transparent), radial-gradient(circle 64px at 70px calc(100% - 5px), #e3e1ff, transparent), radial-gradient(circle 471px at calc(100% - -111px) 393px, #e3e1ff, var(--layout-component-bg));
  --menu-layout-bg: radial-gradient(circle 302px at calc(100% - -157px), #e3e1ff, var(--layout-component-bg));
  --selected-tab-bg: #E0DAF7;
  --selected-tab-color: #6245D8;
  --panel_header_color: #080C32;
  --sidemenu-text-color: #373737;
  --sidemenuhover-text-color: #373737;
  --main-bg: #ebedf0 !important;
  --grid_header_bg: #fbfbfc;
  --grid_rows_bg: #fff;
  --theme_icon_color: #000;
  --theme_iconhover_color: #000;
  --grid_text_color: #080C32;
  --label_text_color: #080C32;
  --cs_label_text_color: #6B6D83;
  --border-color: #D4DCEA !important;
  --btn-light-bg: #f9f9f9;
  --page_selection_border: var(--primary);
  --page_selection_color: #000;
  --column_filter_shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  --tabs-selected-item-bg-color: #f3f3f3;
  --sidemenu_border_color: #b4b4b4;
  --sidemenu_border_shadow: 0px 0 6px rgb(34 0 255 / 80%);
  --scrollbar_color: #d1d4f5;
  --maskbg: rgba(0, 0, 0, 0.3) !important;
  --scheduler_nonWorking_bg: #efecfb !important;
  --scheduler_content_borders: #f5f3f3 !important;
  --scheduler_headerbg: #f4f5f7;
  --scheduler_cellsbg: rgb(251 251 252);
  --scheduler_cellborder:#d1d1d1cc !important;
  --resizer_color: #6245D8;
}

[data-theme="Dark"] {
  --checkbox_color: #816AE0;
  --link_color: rgb(95 139 203);
  --sec_btn_color: #c7c7c7;
  --selectedtab_btn_icon_color: invert(99%) sepia(64%) saturate(2%) hue-rotate(340deg) brightness(112%) contrast(100%);
  --navbar_icons_color: invert(56%) sepia(17%) saturate(293%) hue-rotate(196deg) brightness(92%) contrast(84%);
  --navbar_icons_hover_color: invert(100%) sepia(100%) saturate(0%) hue-rotate(14deg) brightness(101%) contrast(102%);
  --layout_text_color: #848698;
  --user_menu_bg: #816AE0;
  --sidemenu_icons_color: invert(69%) sepia(6%) saturate(473%) hue-rotate(198deg) brightness(92%) contrast(88%);
  --sidemenu_icon_hover_color: invert(83%) sepia(12%) saturate(2000%) hue-rotate(330deg) brightness(98%) contrast(349%);
  --sidemenu_ul_icon_hover_color: invert(73%) sepia(25%) saturate(516%) hue-rotate(341deg) brightness(197%) contrast(101%);
  --sidemenu_ul_icon_hover_grey_color: invert(133%) sepia(198%) saturate(1893%) hue-rotate(135deg) brightness(95%) contrast(0%);
  --sidemenu-parent-selected-item-bg: rgba(83, 85, 110, 0.25);
  --main-component-bg: rgba(18, 19, 31, 0.30);
  --overlay-component-bg: rgb(32 34 49);
  --layout-component-bg: #272839;
  --content_bg: radial-gradient(circle 300px at 151px, #3f4065, transparent), radial-gradient(circle 64px at 70px calc(100% - 5px), #3f4065, transparent), radial-gradient(circle 471px at calc(100% - -111px) 393px, #3f4065, var(--layout-component-bg));
  --menu-layout-bg: radial-gradient(circle 302px at calc(100% - -157px), #3f4065, var(--layout-component-bg));
  --selected-tab-bg: #423B70;
  --selected-tab-color: #fff;
  --panel_header_color: #E6E7EA;
  --sidemenu-text-color: #fff;
  --sidemenuhover-text-color: #fff !important;
  --sidemenuhover-arrow-color: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(326deg) brightness(103%) contrast(102%);
  --main-bg: #39435a;
  --grid_header_bg: #202231;
  --grid_rows_bg: #23304e;
  --theme_icon_color: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(326deg) brightness(103%) contrast(102%);
  --theme_iconhover_color: none;
  --grid_text_color: #E6E7EA;
  --label_text_color: #E6E7EA;
  --cs_label_text_color: #8996AD;
  --border-color: #393B52 !important;
  --btn-light-bg: transparent;
  --page_selection_border: var(--border-color);
  --page_selection_color: #fff;
  --column_filter_shadow: 0 2px 12px 0 rgba(255, 255, 255, 0.1);
  --tabs-selected-item-bg-color: #494747;
  --tree_items_hover_bg: #494747;
  --tree_items_hover_color: var(--grid_text_color);
  --sidemenu_border_color: #6150a6;
  --sidemenu_border_shadow: 0px 0 6px rgb(255 255 255 / 80%);
  --scrollbar_color: #575974;
  --maskbg: rgba(255, 255, 255, 0.3) !important;
  --scheduler_nonWorking_bg: #2f3047 !important;
  --scheduler_content_borders: #303246 !important;
  --scheduler_headerbg: #272839;
  --scheduler_cellsbg: rgb(32 34 49);
  --scheduler_cellborder: #26283f !important;
  --resizer_color: #ffe459;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px; 
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px; 
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

* {
  scrollbar-width: thin; 
  scrollbar-color: #888 #f1f1f1;
}
/* HTML: <div class="loader"></div> */
.loader-container {
  position: absolute;
  width: 100%;
  background-color: rgba(0,0,0,0.1);
  z-index: 9999;
}

.loader-overlay {
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 100%;
  height: 100%; */
  /* background-color: rgba(0, 0, 0, 0.1);  */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; 
}

.loader {
  border: 16px solid #f3f3f3; 
  border-top: 16px solid #3498db; 
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loader {
  width: 80px;
  height: 80px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #ccc #0000;
  animation: l16 1.5s infinite linear;
}
.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}
.loader::before {
  border-color: #e5e5e5 #ccc;
  animation: inherit;
  animation-duration: 1s;
  animation-direction: reverse;
}
.loader::after {
  margin: 8px;
}
@keyframes l16 {
  100% {
    transform: rotate(1turn);
  }
}
