.prdouctSites {
    min-height: 50px;
    max-height: 147px;
    overflow-y: auto;
}
.clearBoth{
    clear: both;
    padding-bottom: 10px;
}

.floatLeft{
    float: left;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 19px;
}
.fuel-system-grid {
    font-size: 12px;
}

.fuel-system-grid th .p-column-header-content {
    white-space: normal;
}

.fuel-system-grid td {
    padding: 8px;
}

.fuel-system-grid .p-button-rounded {
    padding: 0 !important;
    height: fit-content;
}

.fuel-title {
    color: rgba(51, 51, 51, 0.85);
    font-weight: 500;
    font-size: 1.25rem;
}


.child-fuel-grid {
    width: 100%;
    overflow-x: auto;
    font-size: 12px !important;
    border: none !important;
    font-weight: 600;
}

.child-fuel-grid th {
    font-size: 12px !important;
    border-radius: 0px;
    background-color: #f6f8f9;
    color: #414040 !important;
    font-weight: 600;
}

#fuelLinesChildGrid tr:hover {
    background-color:  #eef3f6 !important;
    min-height: 200px;
}
.child-fuel-grid td {
    background-color: transparent !important;
    padding: 0px !important;
    border-radius: 0px !important;
    border:none !important;
}

.child-fuel-grid .p-inputnumber-input.p-inputtext {
    width: 5rem;
}

.delete-button {
    background-color: red !important;
}

.fuel-lines-form label {
    min-width: 15rem;
    max-width: 20rem;
    text-align: left;
    width: 15rem;
}

.fuel-lines-form [type="text"] {
    font-size: 12px;
    padding: 4px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    width: 15rem;
    height: 2.25rem;
}

.fuel-lines-form .form-error {
    font-size: 10px;
    color: red;
}

.fuel-lines-form .form-btns button,
.import-form .form-btns button {
    border-radius: 4px;
    padding: 2px 8px;
}

@media (max-width: 767px) {
    .fuel-system-grid {
        overflow-x: auto;
    }

    .fuel-title {
        font-size: 1.2rem;
    }

    .grid_buttons {
        justify-content: flex-start;
    }
}

.fuel-system-grid {
    width: 100%;
    flex-grow: 1;
}



.FuelSystemLinesTabView .p-tabview-panels,
.FuelSystemLinesTabView .p-tabview-nav {
    padding: 0;
}

.p-multiselect-header>.p-multiselect-select-all>.p-checkbox {
    margin-right: 0.8rem !important;
}

.fsld-fieldset {
    border: 1px solid #C2C2C2 !important;
    font-size: var(--primary-fs);
    margin-left: 5px !important;
    margin-bottom: 10px;
    padding: 2px !important;
    padding-left: 16px !important;
}
.fsld-legend {
    float: none;
    width: auto;
    padding: 0;
    margin-bottom: 0rem;
    font-size: var(--primary-fs);
}
.fsl-filedsetleg{
    margin-left: -2px !important;
    margin-right: 11px !important;
}