.fuel-system-grid {
    font-size: 12px;
}

.fuel-system-grid th {
    padding: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #1a71ef;
}

.fuel-system-grid td {
    padding: 8px;
}

.fuel-title {
    color: rgba(51, 51, 51, 0.85);
    font-weight: 500;
    font-size: 1.25rem;
}

.edit-btn {
    height: 1.5rem;
}

.fuel-form label {
    width: 10rem;
    font-size: 14px;
    text-align: left;
}

.fuel-form [type="text"],
.fuel-form select {
    font-size: 12px;
    padding: 4px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    width: 15rem;
    height: 2.25rem;
}

.fuel-form .p-dropdown {
    width: 15rem;
}

.fuel-form [type="checkbox"] {
    width: 1rem;
}

.fuel-form .form-error {
    font-size: 10px;
    color: red;
}

.fuel-form .form-btns button,
.import-form .form-btns button {
    border-radius: 4px;
    padding: 2px 8px;
}

.fuel-mapping-btns .p-dialog-header-close {
    margin: 0.5rem;
}

.import-form .form-input {
    position: absolute;
    width: 19rem;
    border: 1px solid #d1d5db;
    height: 2.25rem;
    border-radius: 4px;
    top: -3px;
    left: 6rem;
    padding: 5px;
}

.import-form input {
    opacity: 0;
    z-index: 2;
    position: relative;
    cursor: pointer;
}

.import-form .upload-error {
    font-size: 10px;
    color: red;
    margin-left: 6rem;
    margin-top: 0.2rem;
}

.new-reading,
.delete-button {
    border-radius: 4px !important;
    background-color: #337ab7 !important;
    border: 1px solid #eaeeef !important;
    color: #fff;
    font-size: 14px;
    height: 36px;
    margin-bottom: 2px;
}

.reaming_date{
    background-color: #f7f9fa;
    border-radius: 4px;
    color: #24272a;
    font-size: 14px;
    font-weight: 400;
}
.first_last_date{
    background-color: #bcd87a;
    border-radius: 4px;
    color: #24272a;
    font-size: 14px;
    font-weight: 400;
}

#chartjs-tooltip {
    position: absolute;
    background-color: rgba(216, 11, 11, 0.8);
    color: white;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    pointer-events: auto;
    opacity: 0; 
    transition: opacity 0.3s ease;
    
  }
  #inventoryTruckGrid .p-datatable-wrapper {
    height: calc(50vh - 300px);
    min-height: calc(50vh - 393px);
  }
  #inventoryMainGrid .p-datatable-wrapper {
    height: calc(100vh - 400px);
    overflow: auto;
  }
  .grid-resizable-inventory-container {
    /* position: absolute; */
    min-height: 100px;
  }
  .resizable-handle {
    /* position: absolute; */
    right: 10px;
    bottom: 0;
    width: 12px;
    height: 12px !important;
    cursor: nwse-resize;
    border-bottom-right-radius: 5px;
    border-width: 0 3px 3px 0;
    border-style: solid;
    border-color: blue;
    margin-left: 99%;
  }