/* @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css%22'); */

.protean-btn-default {
    background-color: #578ef7;
    border-radius: 4px;
    color: #fff;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 700;
    letter-spacing: normal;
    line-height: normal;
    padding: 12px 16px;
    text-align: center;
    border: none;
}

.protean-btn-default:disabled {
    background-color: #a4a8b1;
    color: #fff;
}

th {
    padding: 8px;
    font-size: 12px;
    font-weight: 600 !important;
    color: #393939;
    border-radius: 6px;
    background: #f6f8f9;
    /* border: 1px solid #fff; */
}

.p-datatable-tbody td {
    font-weight: 500;
}

.p-datatable-row-expansion th {
    color: #fff;
    background: #606573;
    font-weight: 600;
}

.p-datatable-row-expansion .p-datatable-tbody td {
    background: #e6e7e8;
    border-radius: 4px;
    border: 1px solid #fff;
}

.p-datatable-row-expansion th svg {
    color: #fff;
    margin-left: 5px;
    height: 12px;
}

.p-column-header-content>span>svg:not(.p-datatable-row-expansion th svg) {
    margin-left: 5px;
    height: 12px;
}

td {
    padding: 8px;
    text-align: center;
}

input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: transparent !important;
    color: fieldtext !important;
}

.p-column-title:not(.p-datatable-row-expansion .p-column-title) {
    font-weight: 600;
    font-size: 12px;
    color: #414040;
}

.grid_buttons button {
    background: #048d3a !important;
    color: #fff;
    padding: 6px 10px;
    border-radius: 5px;
    margin: 5px !important;
}

.grid_buttonsWObg .styledBtn {
    background: #3e4405;
    color: #fff;
    padding: 6px 10px;
    border-radius: 5px;
    margin: 5px !important;
}

.product-actions-btns button {
    background: #c5200f;
    color: #fff;
    padding: 6px 10px;
    border-radius: 5px;
    margin: 5px !important;
}

.p-inputtext,
.p-inputtext:hover {
    padding: 5px 10px !important;
    border: 1px solid #d1d5db !important;
    font-size: 1rem !important;
}

.p-inputtext:enabled:focus {
    box-shadow: none !important;
}

label {
    font-weight: 500;
    color: #676363;
}

.panel-header {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
}

.p-datatable .p-datatable-header {
    border: none;
    background-color: transparent;
}

.grid_buttons button .pi-plus,
.grid_buttons button .pi-minus {
    margin-right: 5px;
    font-size: 12px;
}

.grid_buttons .p-button-label {
    font-weight: 500;
}

.p-row-toggler svg {
    color: red;
}

td h6 {
    text-align: left;
}

.p-datatable .p-column-header-content {
    justify-content: center;
}

.p-multiselect {
    padding: 5px 10px;
}

.p-multiselect-header {
    padding: 0.5rem;
}

.p-multiselect-items-label {
    color: #676363;
    font-size: 14px;
}

.p-multiselect-items,
.p-dropdown-items {
    padding: 0;
}

.p-multiselect-item {
    padding: 4px 8px;
}

.p-dropdown-item {
    padding: 4px 16px;
}

.p-multiselect-checkbox {
    margin-right: 8px;
}

.p-multiselect-label {
    padding: 0;
    font-size: 12px !important;
    font-weight: 400;
    color: #463e3e !important;
}

.p-multiselect-trigger {
    width: 1rem;
}

.p-dropdown-trigger {
    width: 2rem;
}

.p-dropdown-label.p-inputtext {
    border: 0 !important;
}

.p-tabview-nav-link {
    color: #393939;
    height: 2rem;
}

.p-menu-list {
    padding: 0;
}

.main-content-page-bgcolor {
    background-color: #f7f9fa;
}

.p-inputtext,
.p-inputtext:hover {
    padding: 7px 5px !important;
    font-size: 1rem !important;
    font-size: 12px !important;
    font-weight: 400;
    color: #463e3e;
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-size: 17px !important;
}

.plus-sign,
.import-sign,
.export-sign,
.inventory-varience-button,
.filter-sign,
.Update-button {
    border-radius: 4px !important;
    background-color:#337ab7 !important;
    border: 1px solid #eaeeef !important;
    color: #fff;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: normal;
    padding: 9px;
    margin-top: 2px;
    /* height: 37px; */
}

.plus-sign::before {
    content: "+";
    margin-right: 10px;
}

.export-action-icon,
.import-action-icon,
.filter-action-icon {
    padding-left: 10px;
    font-size: 0.7rem !important;
}

.actions-edit-btn {
    height: 1.5rem;
    width: 1.7rem;
    /* background: #f5f5f5 !important; */
}
.p-column-header-content .p-column-title{
    white-space: nowrap;
}
.form-buttons{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}
.gap-3 {
    gap: 7px !important;
}
.mb-2{
    margin-bottom: 0px;
}
.fsl-main-container{
    gap: 0rem;
}
#tabPanel .p-tabview-panels {
    padding: 0px !important;
}
#tabPanel li {
    margin-bottom: 1px !important;
  }
  
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: #bcd87a !important;
}

.main-title{
    font-family: Roboto;
    color: #24272a;
    font-size: 20px;
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 0px;
    margin-top: 14px;
    padding-left: 11px;
}
#editPencilBtn, .p-button.p-button-secondary.p-button-text{
    color: #1762cd !important;
}
#editPencilBtn, pi{
    color: #1762cd !important;
}
.p-hidden-accessible {
    position: relative;
    pointer-events: none;
    opacity: 0;
    white-space: nowrap;
    width: 4px;
    display: none;
}
fieldset{
    border: 1px solid #C2C2C2 !important;
    padding: 12px !important;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
}
fieldset input{
    width: 50%;
}
.p-icon-field-right > .p-input-icon:last-of-type {
    line-height: 16px;
}
fieldset label{
line-height: 30px;
}